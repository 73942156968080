<template>
  <div class="issues">
    <div class="container">
      <div class="part1">
        <img src="@/assets/img/issues/part1.png" alt="">
      </div>
      <div>
        <img src="@/assets/img/part2.png" class="part" alt="">
        <img src="@/assets/img/issues/contest-info.png" class="title" alt="">
        <div class="info-box">
          <ul class="info-list" v-if="data.list.length != 0">
            <li class="info-item" v-for="(item, index) in data.list" :key="index">
              <a :href="item.link">
                <el-image :src="item.coverUrl" class="info-image" :fit="'cover'"
                  v-if="item.coverUrl != undefined"></el-image>
                <div class="info-message">
                  <div class="middle">
                    <h2 class="info-title">{{ item.title }}</h2>
                    <p class="date">{{ dateFormat(item.createTime) }}</p>
                    <div class="desc">{{ item.description }}</div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
          <div class="pagination">
            <el-pagination v-if="pageInfo.total > 3" @current-change="getList" background :currentPage="pageInfo.pageNum"
              :page-size="pageInfo.pageSize" layout=" prev, pager, next" :total="pageInfo.total"
              style="text-aligin: right">
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="group-wrap">
        <img src="@/assets/img/part3.png" class="part" alt="">
        <img src="@/assets/img/issues/joinus.png" class="title" alt="">
        <div class="QRCode-box">
          <ul class="QRcodeList">
            <li class="QR-item">
              <el-image :src="require('../assets/img/issues/college-qq-group.png')" :fit="'cover'"
                class="QRCode-img"></el-image>
              <div class="code-msg">
                <p>高校组QQ群</p>
              </div>
            </li>
            <li class="QR-item">
              <el-image class="QRCode-img" :src="require('../assets/img/issues/pro-wecaht.png')"
                :fit="'cover'"></el-image>
              <div class="code-msg">
                <p>专业组微信群</p>
              </div>
            </li>
            <li class="QR-item">
              <el-image class="QRCode-img" :src="require('../assets/img/issues/assistant.png')" :fit="'cover'"></el-image>
              <div class="code-msg">
                <p>小助手微信</p>
              </div>
            </li>
            <li class="QR-item">
              <el-image class="QRCode-img" :src="require('../assets/img/issues/benq_official_wechat.png')"
                :fit="'cover'"></el-image>
              <div class="code-msg">
                <p>明基设计专显公众号</p>
              </div>
            </li>
            <li class="QR-item">
              <el-image class="QRCode-img" :src="require('../assets/img/issues/benq_official_weibo.png')"
                :fit="'cover'"></el-image>
              <div class="code-msg">
                <p>明基设计专显微博</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import { listByPage } from "../utils/apis/newApi";
import { brandHit } from "../utils/apis/brandApi";
export default {
  name: "issues",
  setup() {
    const pageInfo = reactive({
      pageNum: 1,
      pageSize: 3,
      total: 4,
    });
    const data = reactive({
      list: [],
    });
    getList(1);

    //获取列表
    function getList(value) {
      console.log(pageInfo.pageNum);
      pageInfo.pageNum = value;
      listByPage({
        pageNum: pageInfo.pageNum,
        pageSize: pageInfo.pageSize,
      }).then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          data.list = res.data.data.list;
          pageInfo.total = res.data.data.total;
        }
      });
    }
    function goLink(item) {
      console.log(item);
      brandHit({ brand: item.name }).then((res) => {
        if (res.data.code === 200) {
          window.location.href = item.href;
        }
      });
    }
    function dateFormat(date) {
      let d = new Date(date)
      let year = d.getFullYear()
      let month = d.getMonth() + 1
      let day = d.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      return year + '-' + month + '-' + day
    }
    return {
      pageInfo,
      data,
      getList,
      goLink,
      dateFormat
    };
  }
};
</script>

<style lang="less" scoped>
.issues {
  background-image: url("../assets/img/bg3.png");
  background-size: cover;
  background-position: center;

  padding: 119px 0 45px 0;

  .container {
    margin: 0 auto;
    .part1 {
      margin-bottom: 119px;
    }
    div {
      margin: 0 auto;

      img {
        margin: 0 auto;
        display: block;
      }

      .part {
        margin-bottom: 19px;
        height: 16px;
      }

      .title {
        height: 74px;
      }

      .QRCode-box {
        height: 270px;
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 72px;
        margin-top: 61px;
      }

      .QRcodeList {
        display: flex;
        font-size: 0px;
        justify-content: space-between;

        .QR-item {
          margin-right: 20px;

          .QRCode-img {
            height: 200px;
            width: 200px;
            border-radius: 10px;
            box-shadow: 6px 6px 20px 0px rgba(120, 89, 67, 0.5);
          }

          .code-msg {
            margin-top: 22px;
            line-height: 28px;
            font-size: 20px;
            font-family: "FZLTCHJW";

            text-align: center;
            color: #fff;
          }
        }

        .QR-item:last-child {
          margin-right: 0px;
        }
      }
    }

    .info-box {
      width: 940px;
      margin: 0 auto;
      margin-top: 55px;
      margin-bottom: 119px;

      .info-list {
        padding-bottom: 70px;

        &:first-child {
          border-top: 1px solid rgba(255, 255, 255, .85);
        }
      }

      .info-item {
        border-bottom: 1px solid #abbfd8;

        a {
          display: flex;
          height: 148px;
          margin: 30px 0;
          text-decoration: none;
          color: #FFFFFF;

          .info-image {
            margin: 0;
            width: 200px;
            height: 148px;
            border-radius: 8px;
            cursor: pointer;
          }

          .info-message {
            flex: 1;
            margin: 0;
            margin-left: 19px;
            // max-width: calc(100% - 418px);
            position: relative;

            .middle {
              position: absolute;
              width: 721px;
            }

            .info-title {
              margin-bottom: 14px;
              font-size: 24px;
              height: 24px;
              line-height: 24px;
              overflow: hidden;
              text-overflow: ellipsis;
              word-wrap: break-word; //文本溢出显示省略号
              white-space: nowrap; //文本不会换行
            }

            .date {
              font-size: 14px;
              margin-bottom: 18px;
              font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            }

            .desc {
              color: #E7F2FF;
              width: 721px;
              height: 75px;
              font-size: 16px;
              line-height: 26px;
              overflow: hidden;
              text-overflow: ellipsis;
              word-wrap: break-word; //文本溢出显示省略号
              // white-space: nowrap; //文本不会换行
            }
          }
        }
      }

      .info-item:last-child {
        margin-bottom: 0px;
      }

      .pagination {
        // height: 16px;
        text-align: center;
      }
    }
  }


}
</style>
