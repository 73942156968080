import request from "../request";

///news/listByPage
export function listByPage(params) {
    return request({
      url: "/news/listByPage",
      method: "GET",
      params
    });
  }
  